/* eslint-disable no-unused-expressions */
import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import registerServiceWorker from './registerServiceWorker';
import { Toaster } from 'react-hot-toast';
import Darkreader from 'react-darkreader';
import './index.css';
import 'react-datepicker/dist/react-datepicker.css';

const overrCSS = 
    '.modal-content{background-color: rgb(36, 37, 37) !important; border-color: rgba(143, 132, 117, 0.2) !important; outline-color: initial; box-shadow: rgb(13 13 13 / 50%) 0px 3px 9px !important; }' +
    '.btn-danger{color: rgb(229, 224, 216) !important; background-color: rgb(141, 49, 44) !important; border-color: rgb(137, 48, 43) !important; }' +
    '.btn-success{color: rgb(229, 224, 216) !important; background-color: rgb(88, 132, 69) !important; border-color: rgb(69, 125, 65) !important; }' + 
    '.btn-info { color: rgb(229, 224, 216) !important; background-color: rgb(47, 116, 135) !important; border-color: rgb(47, 116, 134) !important; }' +
    '.panel-danger > .panel-heading { color: rgb(208, 202, 192) !important; background-color: rgb(50, 52, 52) !important; border-color: rgb(126, 43, 41) !important; }' + 
    '.panel-success > .panel-heading { color: rgb(147, 190, 141) !important; background-color: rgb(52, 67, 35) !important; border-color: rgb(71, 95, 47) !important; } ' +
    '.panel-default > .panel-heading { background-color: rgb(36, 37, 37) !important; border-color: transparent !important; box-shadow: rgb(13 13 13 / 5%) 0px 1px 1px !important; } ' +
    '.panel { background-color: rgb(36, 37, 37) !important; border-color: transparent !important; box-shadow: rgb(13 13 13 / 5%) 0px 1px 1px !important; } ' +
    '.has-success .input-group-addon { color: rgb(147, 190, 141) !important; background-color: rgb(52, 67, 35) !important; border-color: rgb(81, 133, 77) !important; } ' +
    '.navbar-default { background-color: rgb(30, 31, 30) !important; border-color: rgb(59, 61, 61) !important; } ' +
    '.form-control { color: rgb(186, 177, 162) !important; background-color: rgb(26, 27, 27) !important; background-image: none !important; border-color: rgb(66, 70, 69) !important; box-shadow: rgb(0 0 0 / 7%) 0px 1px 1px inset !important; } ' +
    'hr { border-right-color: initial !important; border-bottom-color: initial !important; border-left-color: initial !important; border-top-color: rgb(56, 59, 58) !important; } ' +
    '.cardTitleReport { color: rgb(6 6 6 / 65%) !important; } ' +
    '.cardTitleReportSmall { color: rgb(6 6 6 / 65%) !important; } ' +
    '.cardSubTitleReport{ color: rgb(0 0 0 / 58%) !important; }' +
    '.cardSubTitleReportSmall{ color: rgb(0 0 0 / 58%) !important; }' +
    '.panel-success { border-color: rgba(91, 110, 66, 0.8) !important; }' +
    '.panel-danger { border-color: rgb(65, 67, 67) !important; }' + 
    '.moneyIcon { color: white !important; }' + 
    '.input-group-addon { color: rgb(177, 168, 154) !important; background-color: rgb(34, 35, 35) !important; border-color: rgb(63, 66, 65) !important; }' +
    '.dropdown-menu { background-color: rgb(24, 25, 25) !important; border-color: rgba(138, 127, 111, 0.15) !important; }' +
    '.dropdown-menu > li > a:focus, .dropdown-menu > li > a:hover { color: rgb(207, 200, 187) !important; text-decoration-color: initial !important; background-color: rgb(30, 31, 31) !important; }' +
    '.has-error .input-group-addon { color: rgb(180, 105, 98) !important; background-color: rgb(51, 23, 22) !important; border-color: rgb(121, 56, 52) !important; }' + 
    '.styles-module_chatBody__3SG9H .styles-module_message__2RbY_ .styles-module_triangle__23s3K { border-color: transparent rgb(24 25 25) transparent transparent !important; }' +
    '.index-module_statusbar__vVOYu { background-color: #3c853c !important; color: white !important }' + 
    '.labelPrinterDiv { color: black !important; background-color: white !important; }' + 
    '.labelPrinterDiv svg rect{ fill: rgb(255, 255, 255) !important; }' +
    '.labelPrinterDiv svg g { fill: rgb(0, 0, 0) !important; }' +
    '.labelPrinterDiv svg g rect{ fill: rgb(0, 0, 0) !important; }' +
    '.Dropdown-module_dropdown__1wdNR { background-color: #3c853c !important};';

const imgIgnore = ['.backgroundRed','.backgroundBlue','.backgroundYellow','.backgroundGreen','.backgroundPurple','.cardWhitePaper','.landingPage .intro'];
const theme = {
    mode:1,
    brightness: 90,
    contrast: 95,
    sepia: 15,
    grayscale: 1
};
var mainIsDark = localStorage.getItem('themeDark') == 'true' ? true :  
                localStorage.getItem('themeDark') == null ? true : false;
ReactDOM.render(
    <div>
        <Toaster />
        <Darkreader defaultDarken={mainIsDark ? true : false} fixes={{css:overrCSS, ignoreImageAnalysis:imgIgnore}}  theme={theme} onChange={isDark => {
            let currentTheme = localStorage.getItem('themeDark');
            //console.log('currentTheme');
            //console.log(currentTheme);
            if(currentTheme === null) {
                localStorage.setItem('themeDark', 'true');
                return;
            }
            localStorage.setItem('themeDark', isDark ? 'true' :'false');
            window.location.reload(false);
        }}/>
        <App />
    </div>, document.getElementById('root')
);

registerServiceWorker();
