import React, { lazy, Suspense } from 'react';
import { BrowserRouter as Router, Route, Redirect, Switch } from 'react-router-dom';
import PrivateRoute from '../util/private-routes';
import ProgressBarComponent from "../components/layout/progressbar";

const loading = <><ProgressBarComponent isOpen={true} /></>;
const Login = lazy(() => import('../components/login/index'));
const Setup = lazy(() => import('../components/setup/index'));
const Dashboard = lazy(() => import('../components/dashboard/index'));
const Cards = lazy(() => import('../components/cards/index'));
const Report = lazy(() => import('../components/report/index'));
const Menu = lazy(() => import('../components/menu/index'));
const Configuration = lazy(() => import('../components/configs/index'));
const Clients = lazy(() => import('../components/clients/index'));
const LandingPage = lazy(() => import('../components/landingp/index'));
const Fiscal = lazy(() => import('../components/fiscal/index'));
const CalendarBusiness = lazy(() => import('../components/debits/index'));
const Contacts = lazy(() => import('../components/contacts/index'));
const PrivacyPolicy = lazy(() => import('../components/privacypolicy/index'));
const TermsOfUse = lazy(() => import('../components/termsofuse/index'));

export default () => (
  <Router>
    <div>
      <Suspense fallback={loading}>
        <Switch>
          <Route path="/login" component={Login} />
          <Route path="/signin" component={Setup} />
          <Route path="/home" component={LandingPage} />
          <Route path="/privacypolicy" component={PrivacyPolicy} />
          <Route path="/termsofuse" component={TermsOfUse} />
          <PrivateRoute path="/dashboard" component={Dashboard} />
          <PrivateRoute path="/report" component={Report} />
          <PrivateRoute path="/cards" component={Cards} />
          <PrivateRoute path="/menu" component={Menu} />
          <PrivateRoute path="/clients" component={Clients} />
          <PrivateRoute path="/calendar" component={CalendarBusiness} />
          <PrivateRoute path="/contacts" component={Contacts} />
          <PrivateRoute path="/fiscal" component={Fiscal} />
          <PrivateRoute path="/configuration" component={Configuration} />
          <Route exact path="/" render={() => <Redirect to="/cards" />} />
          <Redirect to="/" />
        </Switch>
      </Suspense>
    </div>
  </Router>
);