import React from 'react';
import Backdrop from '@material-ui/core/Backdrop';
import LinearProgress from '@material-ui/core/LinearProgress';

const classes = {
  backdrop: {
    color: '#fff',
  },
};

export default class ProgressBarComponent extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      isOpen: false
    }
  }
  render() { 
    return (
        <Backdrop className="loading" open={this.props.isOpen}>
          <LinearProgress style={{ width: '30%', borderRadius:5, height:6, marginTop:'20%' }} />
        </Backdrop>
      );
    }
  }
